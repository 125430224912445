@font-face {
  font-family: 'Platform LC Web';
  src: local('Platform LC Web'), url(./fonts/Platform-Regular-Web-88bd00b8.ttf) format('opentype');
  font‑display: block;
}

body {
  background-color: rgb(255, 255, 255);
  margin: 0;
  padding: 0;
  height: 100%;
  font-display: block;
}

.App {
  text-align: center;
  position: relative;
  z-index: 5;
  min-height: 100%;
}
.fallback{
  display: flex;
  height: auto;
  min-height: 100vh;
}